<template>
  <footer class="footer">
    <p>{{ $t("footer.copyright", { year: currentYear }) }} | 
      <a :href="$t('footer.privacy_policy_url')" target="_blank">{{ $t("footer.privacy_policy") }}</a> | 
      <a :href="$t('footer.imprint_url')" target="_blank">{{ $t("footer.imprint") }}</a><br>
    </p>
    <select v-model="currentLocale" @change="changeLanguage">
      <option value="en">English</option>
      <option value="de">Deutsch</option>
    </select>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    currentYear() {
      return new Date().getFullYear(); // Gibt das aktuelle Jahr zurück
    }
  },
  methods: {
    changeLanguage(event) {
      this.$i18n.locale = event.target.value;
    }
  }
};
</script>
