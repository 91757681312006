<template>
  <div class="form-container">
    <h2>{{ $t('step3.title') }}</h2>

    <div class="form-group">
      <label for="firstName">{{ $t('step3.first_name') }}:</label>
      <input
        v-model="formData.firstName"
        type="text"
        id="firstName"
        required
        class="form-control"
        :placeholder="$t('step3.first_name_placeholder')"
      />
      <div v-if="firstNameError" class="error-message">{{ firstNameError }}</div>
    </div>

    <div class="form-group">
      <label for="lastName">{{ $t('step3.last_name') }}:</label>
      <input
        v-model="formData.lastName"
        type="text"
        id="lastName"
        required
        class="form-control"
        :placeholder="$t('step3.last_name_placeholder')"
      />
      <div v-if="lastNameError" class="error-message">{{ lastNameError }}</div>
    </div>

    <div class="form-group">
      <label for="phone">{{ $t('step3.phone') }}:</label>
      <input
        v-model="formData.phone"
        type="tel"
        id="phone"
        required
        class="form-control"
        :placeholder="$t('step3.phone_placeholder')"
      />
      <div v-if="phoneError" class="error-message">{{ phoneError }}</div>
    </div>

    <div class="form-group">
      <label for="email">{{ $t('step3.email') }}:</label>
      <input
        v-model="formData.email"
        type="email"
        id="email"
        required
        class="form-control"
        :placeholder="$t('step3.email_placeholder')"
      />
      <div v-if="emailError" class="error-message">{{ emailError }}</div>
    </div>

    <div class="form-actions">
      <button @click="prevStep" class="btn-prev">{{ $t('step3.back') }}</button>
      <button @click="nextStep" class="btn-next">{{ $t('step3.next') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step3',
  props: {
    formData: Object,
    onNext: Function,
    onPrev: Function
  },
  data() {
    return {
      firstNameError: '',
      lastNameError: '',
      phoneError: '',
      emailError: '',
    };
  },
  methods: {
    prevStep() {
      this.onPrev();
    },
    nextStep() {
      // Fehler zurücksetzen
      this.firstNameError = '';
      this.lastNameError = '';
      this.phoneError = '';
      this.emailError = '';

      // Überprüfen, ob alle erforderlichen Felder ausgefüllt sind
      if (!this.formData.firstName) {
        this.firstNameError = this.$t('step3.fill_first_name');
      }
      if (!this.formData.lastName) {
        this.lastNameError = this.$t('step3.fill_last_name');
      }
      if (!this.formData.phone) {
        this.phoneError = this.$t('step3.fill_phone');
      }
      if (!this.formData.email) {
        this.emailError = this.$t('step3.fill_email');
      }

      // Wenn es Fehler gibt, den Wechsel zum nächsten Schritt verhindern
      if (this.firstNameError || this.lastNameError || this.phoneError || this.emailError) {
        return; // Verhindere den Wechsel zum nächsten Schritt
      }

      // Wenn alle Überprüfungen bestanden sind, zum nächsten Schritt übergehen
      this.onNext();
    }
  }
};
</script>


