<template>
  <div class="dashboard-container">
    <div class="main-layout">
      <nav class="sidebar">
        v1.0.0 ({{ username }})  <!-- Benutzernamen hier anzeigen -->
        <ul>
          <li @click="navigateTo('reports')">Dashboard</li>
          <li @click="navigateTo('customer-registrations')">Kundenregistrierungen</li>
          <li @click="navigateTo('license-management')">Lizenzschlüssel verwalten</li>
          <li @click="navigateTo('settings')">Einstellungen</li>
        </ul>
      </nav>

      <div class="component-container-dashboard">
        <router-view /> <!-- Hier wird die aktuelle Route gerendert -->
      </div>
    </div>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode'; // jwt-decode importieren
import Cookies from 'js-cookie'; // Wenn der Token in Cookies gespeichert wird

export default {
  data() {
    return {
      username: '' // Initialer Wert für den Benutzernamen
    };
  },
  mounted() {
    // Beim Laden der Komponente den Token aus Cookies holen und dekodieren
    const token = Cookies.get('authToken'); // Token aus Cookies (oder localStorage)
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        this.username = decodedToken.sub; // Benutzernamen aus dem Token holen
      } catch (error) {
        console.error('Fehler beim Dekodieren des Tokens:', error);
      }
    }
  },
  methods: {
    navigateTo(route) {
      this.$router.push(`/login/dashboard/${route}`); // Navigiere zur angegebenen Route
    }
  }
};
</script>

<style>
/* Styles für die Dashboard-Komponente */
.dashboard-container {
  display: flex;
}

.main-layout {
  display: flex;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 10px;
}

.component-container-dashboard {
  margin-left: 20px;
  flex-grow: 1;
}
</style>
