<template>
  <div>
    <h1>Lizenzverwaltung</h1>

    <!-- Formular zur Generierung von Lizenzschlüsseln -->
    <div>
      <h2>Lizenzschlüssel generieren</h2>
      <form @submit.prevent="generateLicenseKeys">
        <div>
          <label for="keyCount">Anzahl der Schlüssel:</label>
          <input type="number" v-model="keyCount" min="1" required />
        </div>
        <div>
          <label for="region">Region:</label>
          <select v-model="selectedRegion" required>
            <option value="EU">EU</option>
            <option value="SL">SL</option>
            <option value="CH">CH</option>
          </select>
        </div>
        <button type="submit">Generieren</button>
      </form>
    </div>

    <!-- Tabs für die Filterung der Lizenzschlüssel -->
    <div>
      <ul class="tabs">
       
        <li :class="{ active: selectedTab === 'available' }" @click="selectTab('available')">Verfügbar</li>
        <li :class="{ active: selectedTab === 'reserved' }" @click="selectTab('reserved')">Reserviert</li>
        <li :class="{ active: selectedTab === 'assigned' }" @click="selectTab('assigned')">Aktiviert</li>
        <li :class="{ active: selectedTab === 'all' }" @click="selectTab('all')">Alles</li>
      </ul>
    </div>

    <!-- Suchfeld -->
    <div>
      <h2>Suche nach Lizenzschlüsseln</h2>
      <input type="text" v-model="searchQuery" placeholder="Lizenzschlüssel eingeben..." />
    </div>

    <div v-if="filteredKeys.length > 0">
      <h2>Lizenzschlüssel</h2>
      <table>
        <thead>
          <tr>
            <th>Schlüssel</th>
            <th>Quelle</th>
            <th>Gültigkeit (Monate)</th>
            <th>Erstellt am</th>
            <th>Aktiviert</th>
            <th>Reserviert</th>
            <th>Aktionen</th>
            <th>Einlöse-Datum</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(key, index) in filteredKeys" 
            :key="index" 
            :class="{
              'assigned': key.is_assigned, 
              'reserved': key.is_reserved && !key.is_assigned,
              'available': !key.is_assigned && !key.is_reserved
            }">
            <td>
              <span v-if="key.is_assigned || key.is_reserved" class="no-copy">{{ key.key }}</span>
              <span v-else>{{ key.key }}</span>
            </td>
            <td>{{ key.source }}</td>
            <td>{{ key.validity_period_months }}</td>
            <td>{{ new Date(key.created_at).toLocaleDateString() }}</td>
            <td>{{ key.is_assigned ? 'Ja' : 'Nein' }}</td>
            <td>{{ key.is_reserved ? 'Ja' : 'Nein' }}</td>
            <td>
              <input type="checkbox" v-model="key.is_reserved" @change="confirmUpdateReservedStatus(key)" :disabled="key.is_assigned" />
            </td>
            <td>{{ key.redeemed_at ? new Date(key.redeemed_at).toLocaleDateString() : 'Nicht eingelöst' }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p v-if="statusMessage">{{ statusMessage }}</p>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import jwtDecode from 'jwt-decode'; // Füge dies hinzu
import Cookies from 'js-cookie'; // Füge dies hinzu

export default {
  data() {
    return {
      keys: [],
      searchQuery: '',
      keyCount: 1,
      selectedRegion: 'EU',
      selectedTab: 'available', // Standardmäßig alle Schlüssel anzeigen
      username: process.env.VUE_APP_API_USERNAME,
      password: process.env.VUE_APP_API_PASSWORD,
      client: '',
      statusMessage: '',
      errorMessage: '',
    };
  },
  mounted() {
    this.fetchLicenseKeys();
    this.getUsernameFromToken();
  },
  computed: {
    filteredKeys() {
      // Zuerst nach dem Suchbegriff filtern
      let keys = this.keys.filter(key => key.key.toLowerCase().includes(this.searchQuery.toLowerCase()));

      // Dann basierend auf dem ausgewählten Tab filtern
      if (this.selectedTab === 'available') {
        keys = keys.filter(key => !key.is_assigned && !key.is_reserved);
      } else if (this.selectedTab === 'reserved') {
        keys = keys.filter(key => key.is_reserved && !key.is_assigned);
      } else if (this.selectedTab === 'assigned') {
        keys = keys.filter(key => key.is_assigned);
      }

      return keys;
    },
  },
  methods: {
    fetchLicenseKeys() {
      axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/licensekeys/details/`, {
        auth: {
          username: this.username,
          password: this.password,
        },
      })
      .then((response) => {
        this.keys = response.data;
        this.statusMessage = 'Lizenzschlüssel erfolgreich abgerufen.';
      })
      .catch((error) => {
        this.errorMessage = 'Fehler beim Abrufen der Lizenzschlüssel.';
      });
    },
    generateLicenseKeys() {
      const baseKey = 'LZ211035';
      const order = '9999';
      const keysToGenerate = [];

      for (let i = 0; i < this.keyCount; i++) {
        const randomPart = this.generateRandomKeyPart();
        const newKey = `${baseKey}-${this.selectedRegion}-${randomPart}-${order}`;
        keysToGenerate.push({
          key: newKey,
          source: this.client,
          validity_period_months: 24 // Beispielwert für die Gültigkeitsdauer
        });
      }

      // API-Aufruf zur Generierung der Schlüssel
      axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/licensekeys/`, keysToGenerate, {
        auth: {
          username: this.username,
          password: this.password,
        },
      })
      .then((response) => {
        this.statusMessage = 'Lizenzschlüssel erfolgreich generiert.';
        this.fetchLicenseKeys(); // Lizenzschlüssel neu abrufen
      })
      .catch((error) => {
        this.errorMessage = 'Fehler beim Generieren der Lizenzschlüssel: ' + error.response.data.detail;
      });
    },
    generateRandomKeyPart() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    selectTab(tab) {
      this.selectedTab = tab; // Setze den aktiven Tab
    },
    confirmUpdateReservedStatus(key) {
      const confirmed = confirm(`Sind Sie sicher, dass Sie den Reservierungsstatus für den Schlüssel ${key.key} ${key.is_reserved ? 'aktivieren' : 'deaktivieren'} möchten?`);
      if (confirmed) {
        this.updateReservedStatus(key);
      } else {
        key.is_reserved = !key.is_reserved;
      }
    },
    updateReservedStatus(key) {
      if (!key.id) {
        this.errorMessage = 'Der Lizenzschlüssel hat keine gültige ID.';
        return;
      }

      axios.patch(`${process.env.VUE_APP_BACKEND_URL}/api/licensekeys/${key.id}/`, {
        is_reserved: key.is_reserved,
      }, {
        auth: {
          username: this.username,
          password: this.password,
        },
      })
      .then(() => {
        this.statusMessage = `Reservierungsstatus für Schlüssel ${key.key} aktualisiert.`;
      })
      .catch((error) => {
        this.errorMessage = 'Fehler beim Aktualisieren des Reservierungsstatus.';
      });
    },

    getUsernameFromToken() {
      const token = Cookies.get('authToken'); // JWT-Token aus Cookies holen
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          this.client = decodedToken.sub; // Benutzername aus dem Token extrahieren
        } catch (error) {
          console.error('Fehler beim Dekodieren des Tokens:', error);
        }
      }
    }
  
  },
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.available {
  background-color: #d4edda;
}

.assigned {
  background-color: #f8d7da;
}

.reserved {
  background-color: #d9edf7;
}

.error-message {
  color: red;
}

.no-copy {
  user-select: none;
  pointer-events: none;
  color: gray;
}

/* Tab-Stile */
.tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.tabs li {
  margin-right: 15px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.tabs li.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}
</style>
