import { createRouter, createWebHistory } from 'vue-router';
import Registrierung from '@/views/Registrierung.vue';
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import LicenseManagement from '@/components/LicenseManagement.vue';
import CustomerRegistrations from '@/components/CustomerRegistrations.vue';
import Reports from '@/components/Reports.vue';
import Settings from '@/components/Settings.vue';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

// Routen definieren
const routes = [
  { path: '/', component: Registrierung },
  { path: '/login', component: Login },
  {
    path: '/login/dashboard',
    component: Dashboard,
    children: [
      { path: 'reports', component: Reports, name: 'Reports' },
      { path: 'customer-registrations', component: CustomerRegistrations, name: 'CustomerRegistrations' },
      { path: 'license-management', component: LicenseManagement, name: 'LicenseManagement' },
      { path: 'settings', component: Settings, name: 'Settings' },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }, // Fallback für ungültige Routen
];

// Router erstellen
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Funktion zur Überprüfung des Authentifizierungsstatus anhand des JWT-Tokens
function isAuthenticated() {
  const token = Cookies.get('authToken');
  
  if (!token) {
    return false; // Kein Token vorhanden
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp && decodedToken.exp < currentTime) {
      console.warn('Token ist abgelaufen');
      Cookies.remove('authToken'); // Lösche abgelaufenen Token
      return false; // Token ist abgelaufen
    }

    return true; // Token ist gültig
  } catch (error) {
    console.error('Fehler beim Entschlüsseln des Tokens:', error);
    Cookies.remove('authToken'); // Lösche ungültigen Token
    return false; // Token ist ungültig
  }
}

// Globaler Router Guard
router.beforeEach((to, from, next) => {
  const isAuth = isAuthenticated();

  // Wenn der Benutzer versucht, auf die Login-Seite zuzugreifen, obwohl er eingeloggt ist
  if (to.path === '/login/reports' && isAuth) {
    next('/login/dashboard'); // Umleitung zum Dashboard
  } 
  // Wenn der Benutzer versucht, auf das Dashboard zuzugreifen, ohne eingeloggt zu sein
  else if (to.path.startsWith('/login/dashboard') && !isAuth) {
    next('/login'); // Umleitung zur Login-Seite
  } 
  // Andernfalls, navigiere zur angeforderten Route
  else {
    next();
  }
});

export default router;
