<template>
  <div>
    <h2>{{ $t('step1.title') }}</h2>

    <div class="form-group">
      <label for="serialNumber">
        {{ $t('step1.serial_number') }}:
      </label>
      <input
        v-model="formData.serialNumber"
        @input="formatSerialNumber('serialNumber')"
         @blur="validatePartNumber"
        :type="isSerialNumberLocked ? 'password' : 'text'" 
        id="serialNumber"
        required
        maxlength="19"
        pattern=".{19}"
        class="form-control"
        :placeholder="$t('step1.serial_number_placeholder')"
        :readonly="isSerialNumberLocked"
        @contextmenu.prevent
        @copy.prevent
        @cut.prevent
      />
      <span class="info-txt-code">{{ $t('step1.info_seriennummer') }} <span @click="toggleTooltip" class="tooltip-title">
        {{ tooltipVisible ? 'Info' : 'Info' }}
      </span></span>
      <div>
        <div v-if="tooltipVisible" class="tooltip-box">
          <span v-html="$t('step1.serial_number_tooltip')"></span>
        </div>
      </div>
      <span v-if="errorMessages.serialNumber" class="error-message">
        {{ errorMessages.serialNumber }}
      </span>
    </div>

    <!-- Bestätigungsfeld für die Seriennummer, nur sichtbar, wenn die Seriennummer vollständig eingegeben ist -->
    <div class="form-group" v-if="isSerialNumberComplete">
      <input
        v-model="formData.confirmSerialNumber"
        @input="formatSerialNumber('confirmSerialNumber')"
        @blur="checkSerialNumberMatch" 
        type="text"
        id="confirmSerialNumber"
        required
        maxlength="19"
        pattern=".{19}"
        class="form-control"
        :placeholder="$t('step1.confirm_serial_number_placeholder')"
      />
      <button v-if="isSerialNumberLocked" @click="resetSerialNumber" class="btn-reset">{{ $t('step1.reset_sn') }}</button>
      <p v-if="errorMessages.confirmSerialNumber" class="error-message">
        {{ errorMessages.confirmSerialNumber }}
      </p>
    </div>

    <div class="form-group" v-if="showInvoiceUpload">
      <label for="invoice">{{ $t('step1.upload_invoice') }}</label>
      <input
        type="file"
        id="invoice"
        accept=".pdf, .jpg, .jpeg, .png"
        @change="handleFileUpload"
        class="form-control"
      />
      <span v-if="errorMessages.invoiceFile" class="error-message">
        {{ errorMessages.invoiceFile }}
      </span>
    </div>

    <div class="form-group">
      <label for="purchaseDate">{{ $t('step1.purchase_date') }}:</label>
      <input
        v-model="formData.purchaseDate"
        type="date"
        id="purchaseDate"
        required
        class="form-control"
        :min="minPurchaseDate"
        :max="maxPurchaseDate"
      />
      <br>
      <span class="info-txt-code">{{ $t('step1.info_rechnungsdatum') }}<br></span>
      <span v-if="errorMessages.purchaseDate" class="error-message">
        {{ errorMessages.purchaseDate }}
      </span>
    </div>

    <div class="form-actions-start">
      <button @click="nextStep" class="btn-next">{{ $t('step1.next') }}</button>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  name: 'Step1',
  props: {
    formData: Object,
    onNext: Function,
    onPrev: Function
  },
  data() {
    return {
      extractedData: {
        articleNumber: '',
        productionWeek: '',
        productionYear: ''
      },
      isSerialNumberLocked: false,
      isSerialNumberComplete: false,
      serialNumberMatch: true,
      showInvoiceUpload: false,
      tooltipVisible: false,
      errorMessages: {
        serialNumber: '',
        confirmSerialNumber: '',
        invoiceFile: '',
        purchaseDate: ''
      }
    };
  },
  computed: {
    minPurchaseDate() {
      const today = new Date();
      const threeYearsAgo = new Date();
      threeYearsAgo.setFullYear(today.getFullYear() - 3);
      return threeYearsAgo.toISOString().split('T')[0];
    },
    maxPurchaseDate() {
      const today = new Date();
      return today.toISOString().split('T')[0];
    }
  },
  methods: {
    toggleTooltip() {
      this.tooltipVisible = !this.tooltipVisible;
    },
    async validatePartNumber() {
        const serialNumber = this.formData.serialNumber; // Verwende die vollständige Seriennummer
        try {
            const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/check-part-number/${serialNumber}`);
            if (!response.data.valid) {
              this.errorMessages.serialNumber = this.$t('step1.serial_number_mismatch');

            } else {
                this.errorMessages.serialNumber = '';  // Fehler zurücksetzen, wenn gültig
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                this.errorMessages.serialNumber = this.$t('step1.serial_number_mismatch'); // Fehlermeldung vom Backend
            }
        }
    },
    formatSerialNumber(field) {
      const rawSerial = this.removeNonNumericCharacters(this.formData[field]);
      this.formData[field] = this.applySerialNumberFormat(rawSerial);
      this.updateExtractedData(rawSerial);
      this.errorMessages.serialNumber = '';

      // Überprüfen, ob die Seriennummer vollständig ist
      this.isSerialNumberComplete = this.formData.serialNumber.replace(/[^0-9]/g, '').length === 16;
      
      // Sperren des Feldes, wenn die Seriennummer korrekt eingegeben ist
      if (field === 'serialNumber' && this.validateSerialNumber(this.formData.serialNumber)) {
        this.isSerialNumberLocked = true;
      } else if (field === 'serialNumber') {
        this.isSerialNumberLocked = false; // Entsperren, falls ungültig
      }
    },
    removeNonNumericCharacters(serial) {
      return serial.replace(/[^0-9]/g, '');
    },
    applySerialNumberFormat(serial) {
      if (serial.length > 6) {
        serial = serial.replace(/(\d{6})(\d{0,2})/, '$1-$2');
      }
      if (serial.length > 8) {
        serial = serial.replace(/(\d{6}-\d{2})(\d{0,4})/, '$1-$2');
      }
      if (serial.length > 12) {
        serial = serial.replace(/(\d{6}-\d{2}-\d{4})(\d{0,4})/, '$1-$2');
      }
      return serial;
    },
    updateExtractedData(serial) {
      const unformattedSerial = serial.replace(/[^0-9]/g, '');
      if (unformattedSerial.length === 16) {
        this.extractedData.articleNumber = unformattedSerial.substring(0, 6);
        this.extractedData.productionWeek = unformattedSerial.substring(8, 10);
        this.extractedData.productionYear = unformattedSerial.substring(10, 12);
        this.checkProductionDate();
      } else {
        this.extractedData = {
          articleNumber: '',
          productionWeek: '',
          productionYear: ''
        };
        this.showInvoiceUpload = false;
      }
    },
    validateSerialNumber(serialNumber) {
      const unformattedSerial = serialNumber.replace(/[^0-9]/g, '');
      return unformattedSerial.length === 16;
    },
    checkSerialNumberMatch() {
      const serialNumber1 = this.formData.serialNumber.replace(/[^0-9]/g, '');
      const serialNumber2 = this.formData.confirmSerialNumber.replace(/[^0-9]/g, '');
      this.serialNumberMatch = serialNumber1 === serialNumber2;
      this.errorMessages.confirmSerialNumber = this.serialNumberMatch ? '' : this.$t('step1.serial_number_duplicate');
    },
    checkProductionDate() {
      const productionYear = parseInt(`20${this.extractedData.productionYear}`);
      const productionWeek = parseInt(this.extractedData.productionWeek);
      const productionDate = new Date(productionYear, 0, 1);
      const productionDayOffset = (productionWeek - 1) * 7;
      productionDate.setDate(productionDate.getDate() + productionDayOffset);
      const now = new Date();
      const diffInMonths =
        (now.getFullYear() - productionDate.getFullYear()) * 12 +
        (now.getMonth() - productionDate.getMonth());
      this.showInvoiceUpload = diffInMonths > 30;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const validTypes = ['image/png', 'image/jpeg', 'application/pdf'];
      if (file) {
        if (!validTypes.includes(file.type)) {
          this.errorMessages.invoiceFile = this.$t('step1.invalid_file_type');
          event.target.value = '';
          return;
        }
        if (file.size > 4 * 1024 * 1024) { // 4 MB
          this.errorMessages.invoiceFile = this.$t('step1.file_too_large');
          event.target.value = '';
          return;
        }
        this.formData.invoiceFile = file;
        this.errorMessages.invoiceFile = ''; // Fehlernachricht zurücksetzen
      }
    },
    resetSerialNumber() {
      this.formData.serialNumber = '';
      this.formData.confirmSerialNumber = '';
      this.isSerialNumberLocked = false; // Feld entsperren
      this.isSerialNumberComplete = false; // Status zurücksetzen
      this.errorMessages.serialNumber = '';
      this.errorMessages.confirmSerialNumber = '';
    },
    nextStep() {
    this.errorMessages = { 
        serialNumber: '', 
        confirmSerialNumber: '', 
        invoiceFile: '', 
        purchaseDate: '' 
    }; // Fehlernachrichten zurücksetzen
    
    // Überprüfen der Partnummer, bevor man weitergeht
    this.validatePartNumber().then(() => {
        if (this.errorMessages.serialNumber) {
            // Wenn die Partnummer ungültig ist, nichts tun
            return;
        }

        if (!this.formData.serialNumber) {
            this.errorMessages.serialNumber = this.$t('step1.fill_all_fields');
        } else if (!this.validateSerialNumber(this.formData.serialNumber)) {
            this.errorMessages.serialNumber = this.$t('step1.serial_number_length_error');
        }
        
        if (!this.formData.confirmSerialNumber) {
            this.errorMessages.confirmSerialNumber = this.$t('step1.fill_all_fields');
        } else if (!this.validateSerialNumber(this.formData.confirmSerialNumber)) {
            this.errorMessages.confirmSerialNumber = this.$t('step1.serial_number_length_error');
        }
        
        if (!this.formData.purchaseDate) {
            this.errorMessages.purchaseDate = this.$t('step1.fill_all_fields');
        } else if (!this.isDateInLastThreeYears(this.formData.purchaseDate)) {
            this.errorMessages.purchaseDate = this.$t('step1.purchase_date_invalid');
        }

        this.checkSerialNumberMatch();

        if (this.showInvoiceUpload && !this.formData.invoiceFile) {
            this.errorMessages.invoiceFile = this.$t('step1.fill_invoice_field');
        }

        if (
            this.errorMessages.serialNumber ||
            this.errorMessages.confirmSerialNumber ||
            this.errorMessages.purchaseDate ||
            this.errorMessages.invoiceFile ||
            !this.serialNumberMatch
        ) {
            return; // Wenn es Fehler gibt, nicht weitermachen
        }

        this.onNext(); // Nur fortfahren, wenn alle Validierungen bestanden sind
    });
},

    isDateInLastThreeYears(date) {
      const inputDate = new Date(date);
      const threeYearsAgo = new Date();
      threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
      const today = new Date();
      return inputDate >= threeYearsAgo && inputDate <= today;
    },
    prevStep() {
      this.onPrev();
    }
  }
};
</script>

