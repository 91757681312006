<template>
    <div>
        <h1>Kundenregistrierungen</h1>
        <input
            type="text"
            v-model="searchQuery"
            placeholder="Nach Name, E-Mail, Telefonnummer, Seriennummer oder Schlüssel suchen..."
            class="search-input"
        />
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Telefonnummer</th>
                    <th>Seriennummer</th>
                    <th>Schlüssel</th>
                    <th>Registriert</th>
                    <th>Rechnungsdatum</th>
                    <th>Garantie gültig bis</th>
                    <th>r</th>
                    <th>z</th>
                    <th>m</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="registration in filteredRegistrations" :key="registration.device_serial_number">
                    <td>{{ registration.first_name }} {{ registration.last_name }}</td>
                    <td>{{ registration.email }}</td>
                    <td>{{ registration.phone_number }}</td>
                    <td>{{ registration.device_serial_number }}</td>
                    <td>
                        <span @click="openModal(registration.key, registration.source, registration.created_at)" class="popup-trigger">
                            {{ registration.key }}
                        </span>
                    </td>
                    <td>{{ formatDate(registration.registration_date) }}</td>
                    <td>{{ formatDate(registration.purchase_date) }}</td>
                    <td :class="{'valid': isWarrantyValid(registration.purchase_date, registration.validity_period_months), 'expired': !isWarrantyValid(registration.purchase_date, registration.validity_period_months)}">
                        {{ calculateWarrantyExpiration(registration.purchase_date, registration.validity_period_months) }}
                    </td>
                    <td>
                        <a v-if="registration.invoice_file_path" :href="`${backendUrl}/uploads/${registration.invoice_file_path}`" target="_blank">
                            <i class="fas fa-file-invoice" title="Rechnung anzeigen"></i>
                        </a>
                        <span v-else>Keine Rechnung</span>
                    </td>
                    <td>
                        <a v-if="registration.pdf_file_path" :href="`${backendUrl}/zertifikate/${registration.pdf_file_path}`" target="_blank">
                            <i class="fas fa-file-pdf" title="Zertifikat anzeigen"></i>
                        </a>
                        <span v-else>Kein Zertifikat</span>
                    </td>
                    <td>{{ registration.email_sent ? 'Ja' : 'Nein' }}</td>
                </tr>
            </tbody>
        </table>
  
        <!-- Modal -->
        <div v-if="isModalOpen" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeModal">&times;</span>
                <h2>Details</h2>
                <p><strong>Schlüssel:</strong> {{ modalData.key }}</p>
                <p><strong>Quelle:</strong> {{ modalData.source }}</p>
                <p><strong>Erstellt am:</strong> {{ modalData.createdAt }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CustomerRegistrations',
    data() {
        return {
            registrations: [],
            searchQuery: '', // Hinzufügen einer Suchanfrage
            username: process.env.VUE_APP_API_USERNAME,  
            password: process.env.VUE_APP_API_PASSWORD, 
            isModalOpen: false,
            modalData: {
                key: '',
                source: '',
                createdAt: '',
            },
            backendUrl: process.env.VUE_APP_BACKEND_URL 
        };
    },
    computed: {
        filteredRegistrations() {
            return this.registrations.filter(registration => {
                const fullName = `${registration.first_name} ${registration.last_name}`.toLowerCase();
                const serialNumber = registration.device_serial_number.toLowerCase();
                const key = registration.key.toLowerCase();

                return fullName.includes(this.searchQuery.toLowerCase()) || 
                       registration.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                       registration.phone_number.includes(this.searchQuery) ||
                       serialNumber.includes(this.searchQuery.toLowerCase()) ||
                       key.includes(this.searchQuery.toLowerCase());
            });
        }
    },
    async mounted() {
        await this.fetchRegistrations();
    },
    methods: {
        async fetchRegistrations() {
            try {
                const response = await axios.get(`${this.backendUrl}/api/customer-registrations/`, {
                    auth: {
                        username: this.username,
                        password: this.password,
                    },
                });
                this.registrations = response.data;
            } catch (error) {
                console.error("Fehler beim Abrufen der Registrierungen:", error);
            }
        },
        openModal(key, source, createdAt) {
            this.modalData.key = key;
            this.modalData.source = source;
            this.modalData.createdAt = createdAt;
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        calculateWarrantyExpiration(purchaseDate, validityPeriodMonths) {
            if (!purchaseDate) return null;

            const purchase = new Date(purchaseDate);
            const totalMonths = 36 + (validityPeriodMonths || 0);
            purchase.setMonth(purchase.getMonth() + totalMonths);

            return purchase.toLocaleDateString();
        },
        isWarrantyValid(purchaseDate, validityPeriodMonths) {
            if (!purchaseDate) return false;

            const purchase = new Date(purchaseDate);
            const totalMonths = 36 + (validityPeriodMonths || 0);
            purchase.setMonth(purchase.getMonth() + totalMonths);

            const currentDate = new Date();
            return purchase >= currentDate;
        },
        formatDate(dateString) {
            if (!dateString) return '';

            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const date = new Date(dateString);

            return date.toLocaleDateString('de-DE', options);
        },
    },
};
</script>

<style>
.modal {
    display: block; /* Zeige das Modal an */
}
.modal-content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Farben für Garantiegültigkeit */
.valid {
    background-color: #c8e6c9; /* Hellgrün für gültig */
}

.expired {
    background-color: #ffcdd2; /* Hellrot für abgelaufen */
}

/* Styling für das Suchfeld */
.search-input {
    width: 100%; /* Füllt den gesamten verfügbaren Platz */
    padding: 10px; /* Polsterung für mehr Komfort */
    margin-bottom: 20px; /* Abstand nach unten */
    border: 1px solid #ccc; /* Rahmenfarbe */
    border-radius: 4px; /* Abgerundete Ecken */
    font-size: 16px; /* Schriftgröße */
}

.search-input::placeholder {
    color: #999; /* Farbe des Platzhalters */
    font-style: italic; /* Kursivschrift für den Platzhalter */
}
</style>
