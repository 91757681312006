<template>
    <div class="form-container">
      <h2>{{ $t('conf.danke') }}</h2>
      <p>{{ $t('conf.txt') }}</p>
      <button @click="goToStart" class="btn-next">{{ $t('conf.button') }}</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Confirmation',
    methods: {
      goToStart() {
        this.$emit('reset'); // Ereignis auslösen, um zur ersten Schritt zurückzukehren
      }
    }
  };
  </script>
  