<template>
  <div class="login-container">
    <h1>{{ $t('login.title') }}</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          type="text"
          id="username"
          v-model="username"
          required
          :placeholder="$t('login.username')"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          id="password"
          v-model="password"
          required
          :placeholder="$t('login.password')"
        />
      </div>
      <button type="submit">{{ $t('login.button') }}</button>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
    };
  },
  methods: {
    async handleSubmit() {
      this.errorMessage = ''; // Fehlernachricht zurücksetzen

      // Überprüfung der Eingaben
      if (!this.username || !this.password) {
        this.errorMessage = "Bitte Benutzername und Passwort eingeben.";
        return;
      }

      console.log('Login-Daten:', { username: this.username, password: this.password });

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/login`, {
          username: this.username,
          password: this.password,
        });

        if (response.data.success && response.data.token) {
          // Setze ein Cookie mit dem Authentifizierungsstatus
          Cookies.set('isAuthenticated', true, { expires: 1, sameSite: 'Lax', secure: true }); // Authentifizierungsstatus-Cookie

          // Speichere den Token im Cookie
          Cookies.set('authToken', response.data.token, { expires: 1, sameSite: 'Lax', secure: true });

          // Leite zur Dashboard-Seite weiter
          this.$router.push('login/dashboard/reports');
        } else {
          this.errorMessage = this.$t('login.error');
        }
      } catch (error) {
        console.error('Fehler beim Login:', error.response.data); // Gebe die Fehlermeldung in der Konsole aus
        this.errorMessage = error.response.data.detail || "Ungültiger Benutzername oder Passwort"; // Zeige spezifische Fehlermeldung an
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #1c1c1c;
}

.error {
  color: red;
  text-align: center;
}
</style>
