<template>
  <div class="progress-bar-container">
    <!-- Fortschrittsbalken -->
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: progress + '%' }">
        {{ $t('progress.step_info', { currentStep: currentStep, totalSteps: totalSteps }) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    }
  },
  computed: {
    progress() {
      return (this.currentStep / this.totalSteps) * 100;
    }
  }
};
</script>


