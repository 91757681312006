<template>
  <div>
    <h2>{{ $t('step2.title') }}</h2>

    <div class="form-group">
      <input
        v-model="formData.warrantyKey"
        @input="formatWarrantyKey"
        type="text"
        class="form-control-code"
        required
        maxlength="25"
        pattern=".{25}"
        :placeholder="$t('step2.warranty_key_placeholder')"
      />
      <span v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </span><br>
      <div v-if="responseMessage" class="response-message">
        {{ responseMessage }}
      </div><br>
      <span class="info-txt-code"  v-html="$t('step2.info_text')"></span>
     
    </div>

    <div class="form-actions">
      <button @click="prevStep" class="btn-prev">{{ $t('step2.back') }}</button>
      <button @click="validateAndNext" class="btn-next">{{ $t('step2.next') }}</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Step2',
  props: {
    formData: Object,
    onNext: Function,
    onPrev: Function
  },
  data() {
    return {
      responseMessage: '',
      errorMessage: ''
    };
  },
  methods: {
    formatWarrantyKey() {
      // Entferne alle Nicht-Alphanumerischen Zeichen
      let rawKey = this.formData.warrantyKey.replace(/[^a-zA-Z0-9]/g, '');
      // Wandle alle Buchstaben in Großbuchstaben um
      rawKey = rawKey.toUpperCase();

      // Teile den Key gemäß dem angegebenen Format auf
      const parts = [
        rawKey.substring(0, 8),
        rawKey.substring(8, 10),
        rawKey.substring(10, 18),
        rawKey.substring(18, 22)
      ];

      // Setze den formatierten Key zusammen
      let formattedKey = parts[0];
      if (parts[1]) {
        formattedKey += '-' + parts[1];
      }
      if (parts[2]) {
        formattedKey += '-' + parts[2];
      }
      if (parts[3]) {
        formattedKey += '-' + parts[3];
      }

      this.formData.warrantyKey = formattedKey;
      this.errorMessage = '';
    },
    prevStep() {
      this.onPrev();
    },
    async validateAndNext() {
      // Überprüfe die Länge des Garantie-Keys
      if (!this.formData.warrantyKey || this.formData.warrantyKey.length !== 25) {
        this.errorMessage = this.$t('step2.invalid_key_length');
        return;
      }

      // Verwende den Garantie-Key mit Bindestrichen
      const warrantyKey = this.formData.warrantyKey;

      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/licensekeys/?key=${encodeURIComponent(warrantyKey)}`);
        if (response.status === 200 && response.data.valid) {
          this.responseMessage = ''; // Leere die Rückmeldungsnachricht
          this.onNext(); // Gehe zum nächsten Schritt
        } else {
          throw new Error('Ungültiger Garantie-Key');
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.responseMessage = this.$t('step2.key_not_found_message');
        } else if (error.response && error.response.status === 400) {
          this.responseMessage = this.$t('step2.invalid_key_message');
        } else {
          this.responseMessage = this.$t('step2.error_message');
        }
      }
    }
  }
};
</script>


