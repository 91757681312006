<template>
  <div class="registration-container">
    <!-- Header hinzufügen -->
    <Header />

    <!-- Fortschrittsbalken hinzufügen -->
    <ProgressBar :currentStep="currentStep" :totalSteps="5" />

    <!-- Umhülle die dynamische Komponente mit einem div-Container -->
    <div class="component-container">
      <component
        :is="currentStepComponent"
        :formData="formData"
        :onNext="nextStep"
        :onPrev="prevStep"
        @reset="resetForm"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from 'axios';
import Header from '../components/Header.vue'; // Header importieren
import Footer from '../components/Footer.vue'; // Header importieren
import Step1 from '../components/Step1.vue';
import Step2 from '../components/Step2.vue';
import Step3 from '../components/Step3.vue';
import Step4 from '../components/Step4.vue';
import Confirmation from '../components/Confirmation.vue';
import ProgressBar from '../components/ProgressBar.vue';

export default {
  name: 'Registrierung',
  components: {
    Header,
    Step1,
    Step2,
    Step3,
    Step4,
    Confirmation,
    ProgressBar,
    Footer
  },
  data() {
    return {
      currentStep: 1,
      formData: {
        serialNumber: '',
        confirmSerialNumber: '',
        purchaseDate: '',
        invoice: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        warrantyKey: '',
        invoiceFile: null
      }
    };
  },
  computed: {
    currentStepComponent() {
      switch (this.currentStep) {
        case 1:
          return 'Step1';
        case 2:
          return 'Step2';
        case 3:
          return 'Step3';
        case 4:
          return 'Step4';
        case 5:
          return 'Confirmation';
        default:
          return 'Step1';
      }
    }
  },
  watch: {
    currentStep(newStep) {
      this.updatePageTitle(newStep);
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep < 5) {
        this.currentStep++;
      } else {
        this.submitForm();
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    async submitForm() {
      try {
        const response = await axios.post('http://localhost:8000/submit/', this.formData);
        console.log('Daten erfolgreich gespeichert:', response.data);
        this.currentStep++;
      } catch (error) {
        console.error('Fehler beim Speichern der Daten:', error);
      }
    },
    resetForm() {
      this.currentStep = 1;
      this.formData = {
        serialNumber: '',
        confirmSerialNumber: '',
        purchaseDate: '',
        invoice: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        warrantyKey: '',
        invoiceFile: null
      };
    },
    updatePageTitle(step) {
      let title = '';
      switch (step) {
        case 1:
          title = 'Schritt 1: Seriennummer eingeben';
          break;
        case 2:
          title = 'Schritt 2: Garantie-Key eingeben';
          break;
        case 3:
          title = 'Schritt 3: Persönliche Daten eingeben';
          break;
        case 4:
          title = 'Schritt 4: Zusammenfassung';
          break;
        case 5:
          title = 'Registrierung abgeschlossen';
          break;
        default:
          title = 'Registrierung';
      }
      document.title = title;
    }
  },
  mounted() {
    this.updatePageTitle(this.currentStep);
  }
};
</script>

