<template>
  <div class="form-container">
    <h2>{{ $t('step4.title') }}</h2>
    <p>{{ $t('step4.check_information') }}</p>

    <div class="form-group lineheight">
      <strong>{{ $t('step4.first_name') }}:</strong> {{ formData.firstName }}<br>
      <strong>{{ $t('step4.last_name') }}:</strong> {{ formData.lastName }}<br>
      <strong>{{ $t('step4.email') }}:</strong> {{ formData.email }}<br>
      <strong>{{ $t('step4.phone') }}:</strong> {{ formData.phone }}
    </div>

    <div class="form-group lineheight">
      <strong>{{ $t('step4.serial_number') }}:</strong> {{ formData.serialNumber }}<br>
      <strong>{{ $t('step4.purchase_date') }}:</strong> {{ formData.purchaseDate }}<br>
      <strong>{{ $t('step4.warranty_key') }}:</strong> {{ formData.warrantyKey }}<br>
      <span v-if="formData.invoiceFile">
        <strong>{{ $t('step4.uploaded_file') }}:</strong> {{ formData.invoiceFile.name }}</span>
    
    </div>

    <div class="form-group">
      <label class="checkbox">
        <input  type="checkbox" v-model="isConfirmed" />
        {{ $t('step4.confirmation_message') }}
      </label>
      <p v-if="!isConfirmed && showError" class="error-message">
        {{ $t('step4.error_confirmation') }}
      </p>
    </div>

    <div class="form-actions">
      <button @click="prevStep" class="btn-prev">{{ $t('step4.back') }}</button>
      <button @click="submitForm" class="btn-next" :disabled="loading">{{ $t('step4.submit') }}</button>
    </div>

    <!-- Ladeindikator -->
    <div v-if="loading" class="loading-indicator">Lade...</div>

    <!-- Fehler beim Speichern der Daten -->
    <p v-if="submitError" class="error-message">
      {{ submitError }} <!-- Zeige die Fehlernachricht hier an -->
    </p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Step4',
  props: {
    formData: Object,
    onPrev: Function,
    onNext: Function // Neue Prop für die Übermittlung der Bestätigung
  },
  data() {
    return {
      isConfirmed: false, // Checkbox-Status
      showError: false, // Fehlermeldung für die Checkbox
      submitError: '', // Fehlermeldung für das Speichern
      loading: false // Ladezustand
    };
  },
  methods: {
    prevStep() {
      this.onPrev();
    },
    async submitForm() {
      if (this.isConfirmed) {
        this.showError = false; // Fehler für die Checkbox zurücksetzen
        this.submitError = ''; // Fehler für das Speichern zurücksetzen
        this.loading = true; // Ladezustand aktivieren

        const formDataToSend = new FormData();
        formDataToSend.append('first_name', this.formData.firstName);
        formDataToSend.append('last_name', this.formData.lastName);
        formDataToSend.append('email', this.formData.email);
        formDataToSend.append('phone', this.formData.phone);
        formDataToSend.append('serial_number', this.formData.serialNumber);
        formDataToSend.append('purchase_date', this.formData.purchaseDate);
        formDataToSend.append('warranty_key', this.formData.warrantyKey);
        
        if (this.formData.invoiceFile) {
          formDataToSend.append('invoice_file', this.formData.invoiceFile);
        }

        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/submit/`, formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          console.log('Daten erfolgreich gespeichert:', response.data);
          this.onNext(); // Event an die übergeordnete Komponente senden, um zur Bestätigungsansicht zu wechseln
        } catch (error) {
          this.submitError = 'Fehler beim Speichern der Daten: ' + (error.response ? error.response.data.detail || error.response.data : error.message);
          console.error('Fehler beim Speichern der Daten:', this.submitError);
        } finally {
          this.loading = false; // Ladezustand deaktivieren
        }
      } else {
        this.showError = true; // Fehler anzeigen, wenn die Checkbox nicht markiert ist
      }
    }
  }
};
</script>

