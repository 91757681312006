import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import './assets/global.css';

// Bestimme die Browsersprache
const userLang = navigator.language || navigator.userLanguage;

// Setze die Sprache auf 'de', wenn die Browsersprache 'de' ist, andernfalls auf 'en'
const locale = userLang.startsWith('de') ? 'de' : 'en';

// Erstelle die i18n-Instanz
const i18n = createI18n({
  locale, // Setze die Sprache basierend auf der Browsersprache
  fallbackLocale: 'en', // Falls die Übersetzung nicht gefunden wird
  messages: {
    en,
    de
  }
});

// Dynamisches Hinzufügen von Font Awesome
const fontAwesomeLink = document.createElement('link');
fontAwesomeLink.rel = 'stylesheet';
fontAwesomeLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
document.head.appendChild(fontAwesomeLink);

// Dynamisches Hinzufügen von Typekit
const typekitScript = document.createElement('script');
typekitScript.src = 'https://use.typekit.net/yts8muk.js';
typekitScript.async = true;
typekitScript.onload = () => {
  try {
    Typekit.load({});
  } catch (e) {
    console.error("Fehler beim Laden von Typekit:", e);
  }
};
document.head.appendChild(typekitScript);

// Erstelle und mount die Vue-Anwendung
const app = createApp(App);
app.use(router);
app.use(i18n); // Füge i18n zur Anwendung hinzu
app.mount('#app');
