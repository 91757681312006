<template>
  <div>
    <h1>Dashboard</h1>
    <div>
      <h2>Lizenzschlüsselstatistiken</h2>
      <p>Gesamtanzahl der Schlüssel: {{ stats.total_keys }}</p>
      <p>Zugewiesene Schlüssel: {{ stats.assigned_keys }}</p>
      <p>Verfügbare Schlüssel: {{ stats.available_keys }}</p>

      <h3>Schlüssel nach Quelle</h3>
      <ul>
        <li v-for="(count, source) in stats.source_stats" :key="source">
          {{ source }}: {{ count }}
        </li>
      </ul>

      <!-- Tortendiagramm für Lizenzschlüsselstatistiken -->
      <LicenseKeyPieChart :stats="stats" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LicenseKeyPieChart from './LicenseKeyPieChart.vue'; // Importiere das Tortendiagramm

export default {
  name: 'LicenseKeyStats',
  components: {
    LicenseKeyPieChart // Registriere die Komponente
  },
  data() {
    return {
      stats: {
        total_keys: 0,
        assigned_keys: 0,
        available_keys: 0,
        source_stats: {} // Anpassen: von keys_by_source zu source_stats
      },
      username: process.env.VUE_APP_API_USERNAME, // Verwendung der Umgebungsvariable
      password: process.env.VUE_APP_API_PASSWORD, // Verwendung der Umgebungsvariable
    };
  },
  async mounted() {
    await this.fetchLicenseKeyStats();
  },
  methods: {
    async fetchLicenseKeyStats() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/licensekey-stats/`, {
          auth: {
            username: this.username,
            password: this.password,
          },
        });
        this.stats = response.data; // Stellt sicher, dass die gesamte Struktur übernommen wird
      } catch (error) {
        console.error("Fehler beim Abrufen der Lizenzschlüsselstatistiken:", error);
      }
    }
  }
};
</script>

<style>
/* Fügen Sie hier Ihre CSS-Stile hinzu */
</style>
