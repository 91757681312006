<template>
    <div>
      <h1>Einstellungen</h1>
      <!-- Hier kannst du später den Inhalt für Einstellungen hinzufügen -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'Settings'
  };
  </script>
  

  