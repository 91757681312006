<template>
    <div>
      <h2>Lizenzschlüssel nach Quelle</h2>
      <Pie :chart-data="chartData" width="400" height="400" />
    </div>
  </template>
  
  <script>
  import { Pie } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
  
  // Registriere die Chart.js-Elemente
  ChartJS.register(Title, Tooltip, Legend, ArcElement);
  
  export default {
    name: 'LicenseKeyPieChart',
    components: {
      Pie
    },
    props: {
      stats: {
        type: Object,
        required: true
      }
    },
    computed: {
      chartData() {
        return {
          labels: Object.keys(this.stats.source_stats), // Quellen als Labels
          datasets: [
            {
              label: 'Anzahl der Schlüssel',
              backgroundColor: [
                '#FF6384', // Farbe 1
                '#36A2EB', // Farbe 2
                '#FFCE56', // Farbe 3
                '#4BC0C0', // Farbe 4
                '#9966FF'  // Farbe 5
              ],
              data: Object.values(this.stats.source_stats) // Werte der Quellen
            }
          ]
        };
      }
    }
  };
  </script>
  
  <style>
  /* Fügen Sie hier Ihre CSS-Stile für das Tortendiagramm hinzu */
  </style>
  